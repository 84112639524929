<div [className]="htmlClass" [style.gap]="gap + 'rem'">
    <ng-content select="[title]"> </ng-content>

    <div [ngClass]="labelClass" *ngIf="label">{{ label }}</div>
    <h4 *ngIf="header" class="tool-tip-title" [ngClass]="titleClass">{{ header }}</h4>

    <img
        [className]="iconClass === undefined ? 'icon' : 'icon ' + iconClass"
        (mouseenter)="isMobile ? null : (showInfo = true)"
        (mouseleave)="isMobile ? null : (showInfo = false)"
        (click)="isMobile ? (showInfo = !showInfo) : null"
        src="../../../assets/icons/info-circle.svg"
        alt="{{ 'misc.info' | translate }}"
    />

    <div [className]="placementUp ? 'custom-tool-tip placement-up' : 'custom-tool-tip placement-down'" *ngIf="showInfo" [ngClass]="{ 'full-width': fullWidth }">
        <div class="mb-2 d-flex">
            <span><img src="../../../assets/icons/modal-info-icon.svg" alt="{{ 'misc.info' | translate }}" /></span>
            <span class="tooltip-title">{{ label ? label : header ? header : tooltipTitle ? tooltipTitle : null }}</span>
            <ng-content select="[tooltip-title]"> </ng-content>
        </div>

        <span *ngIf="description">{{ description }}</span>
        <span *ngIf="!description">
            <ng-content select="[description]"></ng-content>
        </span>
    </div>
</div>
