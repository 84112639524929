import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgClass } from '@angular/common';

/**
 * This is a custom component that provides tooltips, where you include the label, title or tinyTitle,
 *
 * Any class you want used, needs to be in this custom component, or a global styling.
 *
 * Properties available:
 *
 * `forInput` - Whether the component is used for input elements.
 *
 * `placementUp` - Whether to place the tooltip above the element, if not assinged it will put it below.
 *
 * `spaceBetween` - Whether to add space between title and icon.
 *
 * `fullWidth` - Whether the tooltip should take up the full width, when content is little.
 *
 * `label` - The label text for the tooltip, mainly used for inputs in forms.
 *
 * `title` - The main title for the tooltip.
 *
 * `tinyTitle` - A smaller title for the tooltip.
 *
 * `description` - The description text for the tooltip.
 *
 * `titleClass` - Additional CSS class for the title element.
 *
 * `labelClass` - Additional CSS class for the label element.
 *
 * `iconStyle` - Additional specific style to icon.
 *
 * `iconClass` - Add class to the icon.
 *
 * `[styles]` - Add specific styling to the icon, use if you dont want to include a class.
 */
@Component({
    selector: 'app-custom-tool-tip',
    templateUrl: './custom-tool-tip.component.html',
    styleUrls: ['./custom-tool-tip.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, TranslateModule]
})

export class CustomToolTipComponent implements OnInit {

    /**
    * **Whether the component is used for input elements.**
    */
    @Input() forInput: boolean;

    /**
     * **Whether to place the tooltip above the element, if not assigned it will put it below.**
     */
    @Input() placementUp: boolean;

    /**
     * **Whether to add space between the title and icon.**
     */
    @Input() spaceBetween: boolean;

    /**
     * **Whether the tooltip should take up the full width when content is little.**
     */
    @Input() fullWidth: boolean;

    /**
     * **The label text for the tooltip, mainly used for inputs in forms.**
     */
    @Input() label: string;

    /**
     * **The main title for the tooltip.**
     */
    @Input() header: string;

    /**
     * **Title used for tooltip popup, if you do not wish to display title outside of popup
     */
    @Input() tooltipTitle: string;

    /**
     * **The description text for the tooltip.**
     */
    @Input() description: string;

    /**
     * **Additional CSS class for the title element.**
     */
    @Input() titleClass = '';

    /**
     * **Additional CSS class for the label element. Defaults is .label class.**
     */
    @Input() labelClass = 'label';

    /**
     * **Additional specific style for the icon.**
     */
    @Input() iconStyle: string;

    /**
     * **Additional CSS class for the icon element.**
     */
    @Input() iconClass: string;

    /**
     * **Add gap between title and icon, in rem**
     */
    @Input() gap = 0;

    showInfo = false;
    isMobile: boolean;
    htmlClass: string;
    titleSet = false;

    constructor(
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnInit(): void {
        this.htmlClass = this.forInput ? 'd-flex custom-tool-tip-wrapper tool-tip-spacebetween tool-tip-placement-input' : 'd-flex custom-tool-tip-wrapper';
        this.spaceBetween ? this.htmlClass += ' justify-content-between' : null;
        this.label ? this.htmlClass += ' label-margin' : null;
        this.header ? this.htmlClass += ' h-margin' : null;
        if (this.header || this.label) {
            this.titleSet = true;
        }
    }

}